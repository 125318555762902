.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.NavBar {
  flex: 0 1 auto;
  display: flex;
}

.Content {
  min-height: 0 !important;
  flex: 1 1 auto;
  background-color: rgb(61, 59, 59);
}

.FormGroup {
  width: 220px;
  height: 200px;
  display: flex;
  justify-content: space-around;
}

.TextField {
  padding: 20px;
  background-color: white;
}

.menu-icon {
  align-items: flex-start !important;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer; 
}